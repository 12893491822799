@import '../abstracts/_mixins';

@mixin input-radio() {
    input[type="radio"] {
        -o-appearance: none;
        appearance: none;
        height: 20px;
        width: 20px;
        transition: all 0.15s ease-out 0s;
        background: #cbd1d8;
        border: none;
        color: #fff;
        cursor: pointer;
        outline-style: none !important;
        border-radius: 50%;
        margin: 0 1rem;

        &:hover {
            background: #9faab7;
        }

        &:checked {
            background: #f27b1f;
            outline-style: none !important;

            &::before {
                height: 20px;
                width: 20px;
                position: absolute;
                content: "✔";
                display: inline-block;
                font-size: -1px;
                text-align: center;
                outline-style: none !important;
            }

            &::after {
                @include keyframes(click-wave, 0.65s);
                background: #f27b1f;
                content: "";
                display: block;
                position: relative;
                z-index: 100;
                outline-style: none !important;
            }
        }
    }

    input[type="radio"].radio {
        &::after {
            border-radius: 50%;
        }
    }
}

@mixin input-checkbox($type) {
    input[type=checkbox] {
        position: absolute;
        height: 0;
        width: 0;
        opacity: 0;

        &+label {
            cursor: pointer;
            position: relative;
            margin: 0;
            color: var(--toc-brown);
            user-select: none;

            &:hover {
                color: var(--toc-orange);
            }

            @if $type==checked {
                @include flex-row-justify(flex-start);

                &::before {
                    content: '';
                    position: absolute;
                    height: 1.5rem;
                    width: 1.5rem;
                    border: 0.1rem solid var(--toc-brown);
                }

                &::after {
                    content: '';
                    position: absolute;
                    left: 0.7rem;
                    bottom: 0.5rem;
                    height: 2rem;
                    width: 0.8rem;
                    opacity: 0;
                    border: solid var(--toc-orange);
                    border-width: 0 0.2rem 0.2rem 0;
                    transform: rotate(30deg);
                    transition: all 0.2s;
                }
            }

            @else if $type==comparator {
                @include flex-row-justify(center);
                &::after {
                    @include icons($fontAwesomeFree, '\f362', 1.5rem, currentColor);
                    padding: 1rem;
                    align-items: center;
                    justify-content: center;
                    width: 3rem;
                    height: 3rem;
                    border: 2px solid currentColor;
                    border-radius: 50%;
                }
            }

            @else {
                @error "Choisir une #{$direction}.";
            }

            &>span {
                margin-left: 2rem;
            }
        }

        &:checked {
            &+label {

                font-weight: bold !important;
                color: var(--toc-orange);

                &:after {
                    @if $type==comparator {
                        color: inherit;
                        border-color: inherit;
                    }

                    @else {
                        opacity: 1;
                        transform: rotate(45deg);
                    }
                }
            }
        }

        &:disabled {
            background-color: green;
            &+label {
                cursor: not-allowed;
                color: var(--toc-dark-grey);
                opacity: 0.6;

                &:after {
                    @if $type==comparator {
                        color: inherit;
                        border-color: inherit;
                    }

                    @else {
                        opacity: 1;
                        transform: rotate(45deg);
                    }

                }
            }
        }
    }
}

@mixin input-datas() {
    div {
        position: relative;

        input[type="text"],input[type="password"],input[type="email"],input[type="tel"],textarea,select {
            display: block;
            width: 100%;
            padding: 0 2rem;
            height: 3.5rem;
            border-radius: 1rem;
            font-size: 1.3rem;
            line-height: 1.5;
            color: var(--toc-dark-grey);
            transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
            box-shadow: none;
            outline: none;
            border: none;

            & + label {
                position: absolute;
                top: 0;
                margin: 0;
                padding: 8px;
                transform: translateX(10px);
                transition: 0.2s;
                color: var(--toc-light-grey);
                pointer-events: none;
            }

            &:required+label {

                &:after {

                    @include icons($fontAwesomeFree, $required, 1rem, var(--toc-red));
                    position: absolute;
                    right: -.2rem;
                    top: 1rem;

                }

            }

            &:focus + label, &:not(:placeholder-shown) + label {
                display: flex;
                transform: scale(0.75) translateY(-100%) translateX(-15%);
                color: var(--toc-dark-grey);
            }
        }
    }
}

@mixin input-file() {
    @include flex-column();

    & .uploader {
        & .filename {
            display: none;
        }

        & .action {
            display: none;
        }
    }

    & input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    label {
        @include flex-row-justify(space-between);
        padding: 0.5rem 2rem;
        border: none;
        border-radius: 1rem;
        font-size: 1.25rem;
        background-color: var(--toc-white);
        cursor: pointer;

        & span {
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        & strong {
            @include button-standart(tertiary);
            // override mixin
            padding: 0.5rem 1rem !important;
            font-size: 1.3rem !important;
        }
    }
}

@mixin textarea() {
    textarea {
        height: 100%;
        width: 100%;
        border-radius: 1rem;
        padding: 1.5rem;
        border: none;
        box-shadow: none;
        outline: none
    }
}