@import './abstracts/_mixins';
@import './abstracts/_variables';
@import './components/title';
@import './components/button';
@import './components/input';

.page__category {

    //OVERRIDE BOOTSTRAP
    @media (min-width : 1200px) {
        .container {
            max-width: 1620px;
        }
    }

    padding-top: 5rem;
    padding-bottom: 5rem;
    justify-content: center;

    @media (min-width : $breakpoint-lg) {
        &__title {
            grid-template-rows: repeat(2, 1fr);
            grid-template-columns: initial;
        }
    }

    @media (max-width : $breakpoint-lg) {
        padding-top: 2rem;
    }
}

.products-list {
    &__title {
        @include flex-column();
        align-items: flex-start;
        padding-bottom: 3rem;
        row-gap: 2rem;
        border-bottom: 2px solid var(--toc-orange);

        &__text {
            &>h2 {
                @include h2();
                font-size: 3rem;
            }

            &>span {
                font-weight: bold;
                font-size: 2rem;
                color: var(--toc-orange);
            }
        }

        &__filter {
            @include select();

            //override mixin
            select {
                border: 1px solid var(--toc-light-grey);
                border-radius: 0;
                padding: 0;
                width: auto !important;
            }

            display: flex;
            align-items: center;

            label {
                margin: 0 1rem;
            }
        }

        &__compare {
            button {
                @include button-cta(primary);

                &#clearComparedProducts {
                    @include button-standart(tertiary);
                    padding: 1rem;
                    margin-left: 1rem;
                    font-size: 1rem;
                    border: 1px solid var(--orange);
                    text-shadow: none;
                    box-shadow: none;
                    display: none;

                    &.shown {
                        display: inline-block;
                    }
                }
            }
        }

        &__store {
            display: block;

            &__button {
                @include button-standart(primary)
            }
        }
    }

    &__articles {
        .product__list {
            row-gap: 2rem;
            padding-top: 2rem;

            &__item {
                @include productCard();
                @include input-checkbox(comparator);
                list-style-type: none;

                .card__header__product__images {
                    display: flex;
                    align-items: center;
                    position: relative;

                    .promo_layer {
                        background-color: transparent;
                        left: 0;
                        position: absolute;
                        transform: translateY(calc(-50% + .75rem));
                        top: 50%;
                        width: calc(100% + 2.5rem);
                        margin: -.75rem -1.25rem;
                    }

                }

            }
        }
    }
}

/** ******************************* PRODUCT LIST RESPONSIVE ******************************* */
@media (max-width : $breakpoint-lg) {

    .products-list {
        &__title {

            padding-bottom: 0;

            &>div {
                @include flex-column();
                row-gap: 2rem;
            }

            &__text {
                span {
                    display: block;
                    text-align: center;
                }
            }

            &__filter {
                width: 100%;

                form {
                    width: 100%;

                    .select {
                        @include flex-row-justify(space-between);

                        label {
                            width: 15%;
                            text-align: center;

                            @media (max-width : $breakpoint-sm) {
                                display: none;
                            }
                        }

                        select {
                            width: 85% !important;

                            @media (max-width : $breakpoint-sm) {
                                width: 100% !important;
                            }
                        }
                    }
                }
            }

            &__compare {
                .compare-form {
                    display: none;
                }
            }

            &__store {
                display: none;
            }
        }
    }
}